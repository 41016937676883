import { SharedConfig } from '../../../common/model/config';

export const qaSharedConfig: SharedConfig = {
  timeout: 30000,
  pollInterval: 15000,
  cacheDurationMinutes: 30,
  maximumDistanceKilometers: 20,
  companyPaginationStep: 6,
  sentryDSN: 'https://21d1eef256b44e7986b1ba5c2032e8b9@sentry.monitor.onyo.com/32',
  numberColumnsBreakPointsAggregator: {
    xs: 12,
    md: 6,
    lg: 4,
  },
};
